import { Card, Table } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { AdminService } from "src/resources/AdminService";
import { GlobalVariables } from "src/resources/GlobalVariables";

const AdminSonAltiAy = () => {
  const [initialRender, setInitialRender] = useState(true);
  const [raporlar, setRaporlar] = useState(null);
  const [total, setTotal] = useState(null);

  useEffect(() => {
    if (initialRender) {
      setInitialRender(false);
      AdminService.adminSonAltiAy().then((data) => {
        console.log(data.data);
        setRaporlar(data.data.data);
        setTotal(data.data.data2);
      });
    }
  }, [initialRender]);
  const columns = [
    {
      title: "Ay",
      dataIndex: "month",
    },
    {
      title: "Ziyaret",
      dataIndex: "ziyaret",
      className: "text-center",
    },
    {
      title: "Aile Ziyareti",
      dataIndex: "aileziyareti",
      className: "text-center",
    },
    {
      title: "Kamp",
      dataIndex: "kamp",
      className: "text-center",
    },
    {
      title: "Mutevelli",
      dataIndex: "mutDevam",
      className: "text-center",
    },
    {
      title: "Sohbet",
      dataIndex: "sohbetDevam",
      className: "text-center",
    },
  ];
  const columns2 = [
    {
      title: "Mentor",
      dataIndex: "mentor",
      sorter: (a, b) => GlobalVariables.compare(a.mentor, b.mentor),
    },
    {
      title: "Gender",
      dataIndex: "gender",
      sorter: (a, b) => GlobalVariables.compare(a.gender, b.gender),
    },
    {
      title: "Mut.Sayi",
      dataIndex: "mutevelli",
      sorter: (a, b) => a.mutevelli - b.mutevelli,
    },
    {
      title: "Ziyaret",
      dataIndex: "ziyaret",
      className: "text-center",
      sorter: (a, b) => a.ziyaret - b.ziyaret,
    },
    {
      title: "Aile Ziyareti",
      dataIndex: "aileziyareti",
      className: "text-center",
      sorter: (a, b) => a.aileziyareti - b.aileziyareti,
    },
    {
      title: "Kamp",
      dataIndex: "kamp",
      className: "text-center",
      sorter: (a, b) => a.kamp - b.kamp,
    },
    {
      title: "Mutevelli",
      dataIndex: "mutDevam",
      className: "text-center",
      sorter: (a, b) => a.mutDevam - b.mutDevam,
    },
    {
      title: "Sohbet",
      dataIndex: "sohbetDevam",
      className: "text-center",
      sorter: (a, b) => a.sohbetDevam - b.sohbetDevam,
    },
  ];

  const GetLastSixMonth = (mutveri, ind) => {
    let months = [];
    let monthsRequired = 6;

    for (let i = monthsRequired; i >= 1; i--) {
      months.push({
        key: i,
        month: moment().locale("tr").subtract(i, "months").format("MMMM YYYY"),
        ziyaret: mutveri.filter(
          (item) =>
            moment(item.tarih).locale("tr").format("MMMM YYYY") ===
            moment().locale("tr").subtract(i, "months").format("MMMM YYYY")
        )[0]?.ziyaret,
        aileziyareti: mutveri.filter(
          (item) =>
            moment(item.tarih).locale("tr").format("MMMM YYYY") ===
            moment().locale("tr").subtract(i, "months").format("MMMM YYYY")
        )[0]?.aileziyareti,
        kamp: mutveri.filter(
          (item) =>
            moment(item.tarih).locale("tr").format("MMMM YYYY") ===
            moment().locale("tr").subtract(i, "months").format("MMMM YYYY")
        )[0]?.kamp,
        mutDevam: mutveri.filter(
          (item) =>
            moment(item.tarih).locale("tr").format("MMMM YYYY") ===
            moment().locale("tr").subtract(i, "months").format("MMMM YYYY")
        )[0]?.mutDevam,
        sohbetDevam: mutveri.filter(
          (item) =>
            moment(item.tarih).locale("tr").format("MMMM YYYY") ===
            moment().locale("tr").subtract(i, "months").format("MMMM YYYY")
        )[0]?.sohbetDevam,
      });
    }
    return months;
  };
  return (
    <div className="max-w-4xl">
      {total && (
        <Table
          title={() => (
            <span className="text-base text-blue-500">
              Toplu son 6 aylik rapor
            </span>
          )}
          dataSource={total}
          columns={columns2}
          pagination={false}
          size="small"
          bordered
          rowKey="mentor"
        />
      )}
      <br />
      <br />
      {raporlar &&
        raporlar.map((mentor, index) => (
          <Card className="border border-gray-300 mb-6" key={index}>
            <p className="text-base text-red-400 mb-6">
              {mentor.key} mutevellileri son 6 ay raporu
            </p>
            {mentor.muts.map((mut, ind) => (
              <div key={ind}>
                <Table
                  title={() => (
                    <span className="text-base text-blue-500">
                      {mut.veri[0].mutevelli} son 6 aylik raporu
                    </span>
                  )}
                  dataSource={GetLastSixMonth(mut.veri, ind)}
                  columns={columns}
                  pagination={false}
                  size="small"
                  bordered
                  rowKey="key"
                />
                <br />
                <br />
              </div>
            ))}
          </Card>
        ))}
    </div>
  );
};

export default AdminSonAltiAy;
