import { Table } from "antd";
import React, { useEffect, useState } from "react";
import { AdminService } from "src/resources/AdminService";

const CampMembers = () => {
  const [members, setMembers] = useState(null);
  const [initialRender, setInitialRender] = useState(true);
  useEffect(() => {
    if (initialRender) {
      setInitialRender(false);
      AdminService.campMembers().then((data) => {
        console.log(data.data);
        setMembers(data.data);
      });
    }
  }, [initialRender]);
  const columns = [
    {
      title: "Member",
      dataIndex: "name",
    },
    {
      title: "Gender",
      dataIndex: "gender",
    },
    {
      title: "Age",
      dataIndex: "age",
    },
    {
      title: "Year Level",
      dataIndex: "yearlevel",
    },
    {
      title: "Special Dietary",
      dataIndex: "special",
    },
    {
      title: "Main Applicant",
      dataIndex: "camper",
    },
    {
      title: "Relation",
      dataIndex: "relation",
    },
    {
      title: "Share Option",
      render: (item) => (
        <span>{item.relation === "Main Applicant" ? item.friend : ""}</span>
      ),
    },
  ];
  return (
    <div>
      <Table
        rowKey="id"
        columns={columns}
        dataSource={members}
        loading={members === null}
        bordered
        size="small"
        pagination={false}
        title={() =>
          members && <span>Registered Members. {members.length} total.</span>
        }
      />
    </div>
  );
};

export default CampMembers;
